<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div>
          <i18n path="overview.text" tag="div" class="bsat__paragraph">
            <template #link2>
              <a @click="goToTab(3)">{{ $t("overview.link2") }}</a>
            </template>
          </i18n>
        </div>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>
        <!-- ********************** RECOMMENDED RESOURCES ************************** -->

        <div class="bsat__subtitle mb-4">
          {{ $t("recommendedResources.title") }}
        </div>

        <!-- <div class="bsat__paragraph mt-n3 mb-6">
          {{ $t("recommendedResourcesIntro") }}
        </div> -->

        <ul class="ml-0 ml-sm-4 bsat__ul">
          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph") }}
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(2)">{{
                    $t("recommendedResources.paragraph2.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.catalogue.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.catalogue.title")
                  }}</strong>
                </template>
              </i18n>
              <a
                :href="catalogueLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </a>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.bestPractices.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.bestPractices.title")
                  }}</strong>
                </template>
              </i18n>
              <router-link
                to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </router-link>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.regionalPolicies.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>
                    {{ $t("recommendedResources.regionalPolicies.link") }}
                  </strong>
                </template>
              </i18n>
              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                to="/repository-of-regional-policies-to-support-bio-based-models"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </div>
          </li>

          <!-- <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph3") }}
            </div>
          </li> -->

          <li>
            <div>
              <i18n
                path="recommendedResources.trainingSectors.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.trainingSectors.title")
                  }}</strong>
                </template>
              </i18n>
              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                to="/training-in-bioeconomy-sectors"
                >{{ $t("visit") }}</v-btn
              >
            </div>
          </li>
        </ul>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
	"en": {
		"implementationRecommendations": {
			"proposedMethods": {
				"title": "Proposed methods",
				"unorderedList": [
					"Use results of backcasting workshop to revise if all planned activities in the roadmap respond to updated priority areas and goals. ",
					"Strategy update working group should propose missing activities within the roadmap to reach all goals according to the updated priority areas.",
					"Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group. "
				]
			},
			"task": {
				"text": "{title}: Based on step 3, up-date (if necessary) specific activities in roadmap to respond to updated priority areas, goals and specific goals. ",
				"title": "Task"
			},
			"taskLeader": {
				"text": "{title}: Strategy update working group. ",
				"title": "Task leader"
			},
			"title": "Implementation Recommendations"
		},
		"overview": {
			"link2": "Phase 3",
			"text": "In this step, new actions can be added to the roadmap under existing priority areas, as well as to updated and new priority areas. All according to the updated strategy and based on the analysis of strategy implementation and latent bioeconomy challenges (Step 2, {link2}), participatory process (Step 2 and Step 3, {link2}) and final decisions within the strategy update working group.   "
		},
		"recommendedResources": {
			"bestPractices": {
				"text": "{title} Consult a selection of bio-based solutions from the POWER4BIO catalogue, which thoroughly describe their potential for replicability in rural areas",
				"title": "Best practice examples:"
			},
			"catalogue": {
				"text": "{title} Get inspired by the POWER4BIO catalogue of bio-based solutions about key enabling aspects to develop and/or good practices to deploy at regional level.",
				"title": "Catalogue of bio-based solutions:"
			},
			"paragraph": "Current regional bioeconomy strategy vision ",
			"paragraph2": {
				"link": "Step 2",
				"text": "Analysis of regional bioeconomy challenges from interviews with regional experts ({link}) Template for interviews with regional experts ({link})"
			},
			"paragraph3": "Guidelines for cross-regional cooperation ",
			"regionalPolicies": {
				"link": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy.",
				"text": "{link} Identify areas of priority, based on supporting policies thoroughly described and good examples . Policy information from the bioeconomy field at this stage, from a helicopter view, will help the region assess the general elements to bear in mind in the following steps and phases. Specially consult: Policy overview and Brief tips for policy analysis in the field of bioeconomy"
			},
			"title": "Recommended resources ",
			"trainingSectors": {
				"text": "{title} POWER4BIO training materials are an elaborated source of elaborated analysis and description to use for training purposes. They include webinars developed in the 4 bio applications (bioenergy. Food&feed, biochemicals and biomaterials), dealing with a wide range of key aspects.",
				"title": "Training in Bioeconomy sectors: "
			}
		},
		"title": "Step 1: Revision and update of roadmap of the regional bioeconomy strategy",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
	}
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "UpdatePhase4Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
